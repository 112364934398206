/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const pageLogWrite = (to) => {
  // const { uid, email } = store.getters["auth/user/GET_USER"];
  // Vue.prototype.$firebase
  //   .firestore()
  //   .collection("pageLogs")
  //   .add({
  //     uid,
  //     email,
  //     to: to.path,
  //     createdAt: new Date(),
  //   })
  //   .catch((e) => console.log(e.message));
};

const levelCheck = (level) => {
  return (to, from, next) => {
    if (!store.getters["auth/user/GET_USER"]) {
      if (to.path !== "/login") {
        if (to.path !== "/") {
          Vue.prototype.$toasted.global.error("로그인이 필요합니다.");
        }
        return next("/login");
      }
    } else {
      if (store.getters["auth/user/GET_CLAIMS"] === null)
        return next("/mypage");
      if (store.getters["auth/user/GET_CLAIMS"].level > level)
        return next("/404");
      pageLogWrite(to);
    }
    next({ query: to.query });
  };
};
const routes = [
  {
    path: "/",
    redirect: "page",
  },
  {
    path: "/page",
    component: () => import("@/views/Page/Index.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Page/Home/Home.vue"),
      },
      {
        path: "about",
        name: "AboutUs",
        component: () => import("@/views/Page/AboutUs/AboutUs.vue"),
      },
      {
        path: "service",
        name: "Service",
        meta: { transparentAppBarOnTop: true },
        component: () => import("@/views/Page/Service/Service.vue"),
      },
      {
        path: "edu",
        name: "Education",
        component: () => import("@/views/Page/Edu/Edu.vue"),
      },
      {
        path: "order",
        name: "Order",
        component: () => import("@/views/Page/Order/Index.vue"),
      },
      {
        path: "oldorder",
        name: "oldOrder",
        component: () => import("@/views/Page/Order/Order.vue"),
      },
      {
        path: "products",
        name: "Products",
        component: () => import("@/views/Page/Products/Products.vue"),
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("@/views/Page/Portfolio/Portfolio.vue"),
      },
      {
        path: "portfolio/:id",
        component: () => import("@/views/Page/Portfolio/Portfolio.vue"),
      },
      {
        path: "press",
        name: "Press",
        component: () => import("@/views/Page/Press/Press.vue"),
      },
    ],
  },
  {
    path: "/mypage",
    beforeEnter: levelCheck(3),
    component: () => import("@/views/MyPage/Index.vue"),
    children: [
      {
        path: "",
        name: "MyPage",
        component: () => import("@/views/MyPage/MainHome.vue"),
      },
      {
        path: "edit",
        name: "MyPage-Edit",
        component: () => import("@/views/MyPage/EditProfile.vue"),
      },
      {
        path: "orders",
        name: "MyPage-Orders",
        component: () => import("@/views/MyPage/MyOrders.vue"),
      },
    ],
  },
  {
    path: "/admin",
    beforeEnter: levelCheck(2),
    component: () => import("@/views/Admin/Index.vue"),
    children: [
      {
        path: "order",
        name: "Admin-Order",
        component: () => import("@/views/Admin/Order/Index.vue"),
      },
      {
        path: "",
        name: "Admin-Dashboard",
        component: () => import("@/views/Admin/AdminDashboard.vue"),
      },
      {
        path: "users",
        name: "Admin-Users",
        component: () => import("@/views/Admin/AdminUsers.vue"),
      },
      {
        path: "users/:uid",
        component: () => import("@/views/Admin/AdminUsers.vue"),
      },
      {
        path: "orders",
        name: "Admin-Orders",
        component: () => import("@/views/Admin/AdminOrders.vue"),
        beforeEnter: (to, from, next) => {
          if (!to.query.type) {
            next({ path: "/admin/orders", query: { type: "NEW" } });
          } else {
            next();
          }
        },
      },
      {
        path: "orderlist",
        name: "Admin-Order-List",
        component: () => import("@/views/Admin/AdminOrderList.vue"),
      },
      {
        path: "calendar",
        name: "Admin-Calendar",
        component: () => import("@/views/Admin/Calendar/Index.vue"),
        // component: () => import("@/views/Admin/AdminCalendar.vue"),
      },
      {
        path: "portfolio",
        name: "Admin-Portfolio",
        component: () => import("@/views/Admin/AdminPortfolio.vue"),
      },
      {
        path: "press",
        name: "Admin-Press",
        component: () => import("@/views/Admin/AdminPress.vue"),
      },
      {
        path: "printer",
        name: "Admin-Printer",
        component: () => import("@/views/Admin/Printer/Index.vue"),
      },
      {
        path: "devtest",
        name: "Dev-Test",
        component: () => import("@/views/Admin/DevTest.vue"),
      },
    ],
  },

  /**
   * AUTHORIZATION
   */
  {
    path: "/auth",
    component: () => import("@/views/Authorization/Index.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Authorization/LoginPage.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/user/GET_USER"]) {
            next();
          } else {
            if (store.getters["auth/user/GET_CLAIMS"].disabled) {
              next("/signup");
            } else {
              Vue.prototype.$toasted.global.error("이미 로그인 되었습니다.");
              next("/page");
            }
          }
        },
      },
      {
        path: "/signup",
        name: "SignUp",
        component: () => import("@/views/Authorization/SignUpPage.vue"),
      },

      {
        path: "/callback",
        name: "callback",
        redirect: "/login",
        component: () => import("../views/Authorization/callback/index.vue"),
        children: [
          {
            path: "kakaotalk",
            name: "callback-kakaotalk",
            component: () =>
              import("@/views/Authorization/callback/KakaoTalk.vue"),
          },
          {
            path: "naver",
            name: "callback-naver",
            component: () => import("@/views/Authorization/callback/Naver.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/404",
    name: "404",
    component: () => import("../views/Page/404.vue"),
  },

  {
    path: "*",
    redirect: "/404",
  },
];

// VueRouter 인스턴스 생성: 애플리케이션의 라우팅을 관리합니다.
const router = new VueRouter({
  mode: "history", // HTML5 History Mode 사용
  base: process.env.BASE_URL, // 기본 URL 설정
  routes, // 라우트 설정
});

// Firebase 초기화 대기: Firebase 인증 상태가 준비될 때까지 대기합니다.
const waitFirebase = () => {
  return new Promise((resolve, reject) => {
    let cnt = 0;
    const timer = setInterval(() => {
      // Firebase 로드 상태 확인
      if (store.getters["auth/user/GET_FIREBASE_LOADED"]) {
        clearInterval(timer);
        resolve();
      } else if (cnt++ > 500) {
        // 최대 대기 시간 초과 시 에러 처리
        clearInterval(timer);
        reject(Error("서버 연결을 실패했습니다."));
      }
    }, 10);
  });
};

// 라우터 이동 전의 마지막 라우트를 저장하기 위한 커스텀 프로퍼티
router.prevRoute = null;

// 전역 가드: 모든 라우트 전환 전에 실행됩니다.
router.beforeEach(async (to, from, next) => {
  // 이전 라우트 저장
  router.prevRoute = from;

  try {
    // Firebase 초기화 대기
    await waitFirebase();

    const user = store.getters["auth/user/GET_USER"];

    // 회원가입 페이지를 떠날 때 사용자 확인
    if (from.path === "/signup") {
      if (to.query.confirm === true) {
        return next();
      }
      if (user && !user.phoneNumber) {
        if (window.confirm("가입을 취소하시겠습니까?")) {
          // 사용자가 가입 취소를 원하는지 확인
          await Vue.prototype.$axios.delete("/users/" + user.uid);
          store.dispatch("auth/user/SET_LOGOUT", "");
          return next(); // 페이지 이동 취소
        } else {
          return next(false); // 사용자가 취소를 선택했을 때
        }
      }
    }

    // 로그인, 회원가입, 소셜 로그인 콜백 페이지는 검사 없이 진행
    if (
      to.path === "/signup" ||
      to.path === "/login" ||
      to.path.includes("/callback/")
    ) {
      next();
      return;
    }

    // 로그인된 사용자의 기본 정보가 없는 경우 회원가입 페이지로 리다이렉트
    if (user && (!user.displayName || !user.phoneNumber)) {
      next("/signup");
      return;
    }
    // 다음 라우트로 진행
    next();
  } catch (e) {
    console.error(e.message);
    Vue.prototype.$toasted.global.error(e.message);
    next(false); // 에러 발생 시 페이지 이동 취소
  }
});

// 라우터 이동 후 실행되는 후크: 필요한 경우 로직 추가
router.afterEach((to, from) => {
  // 예: 페이지 이동 후에 진행 상태를 종료하고 싶은 경우 사용
  // Vue.prototype.$Progress.finish();
});

// 라우터 오류 처리: 전역 오류 처리 로직
router.onError((e) => {
  Vue.prototype.$toasted.global.error(e.message);
});

export default router;
